import React, { Component } from 'react';
import Axios from 'axios';
import Alert from '../Alerts/Alert';
import PortalConfig from '../../config';
import Logo from '../../images/logo.png';
import { Redirect, Link } from "react-router-dom";

class ForgetPassword extends Component
{
    state = {
        user_name: '',
        loading: false,
        loading_message: '',
        error_message: '',
        success_message: '',
        uploaded_logo: 'https://crm.bit4finance.de/cache/logo.png',
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        // Check both password and confirm password are entered, if not, return error
        if(!this.state.user_name) {
            this.setState({
                error: true,
                error_message: 'Username is mandatory',
                loading: false,
            });
            return false;
        }

        this.setState({ loading: true });

        const post_data = {
            user_name: this.state.user_name,
        };

        Axios.post(PortalConfig.crm_url + '/forget-password', post_data).then((response) => {
            if(response.data.status === true) {
                // Show success message
                this.setState({
                    success_message: response.data.message,
                    loading: false,
                    error: false,
                    error_message: '',
                });
            }
            else {
                this.setState({
                    error: true,
                    error_message: 'Something went wrong!',
                    loading: false,
                    success_message: '',
                });
            }
        })
        .catch((error) => {
            var error_message;
            if(error.response) {
                error_message = error.response.data.message;
            }
            else {
                error_message = error.message 
            }
            
            // Set error status and error message
            this.setState({
                error: true,
                error_message: error_message,
                loading: false,
                success_message: '',
            });
        });
    }

    componentWillMount() {
        document.body.classList.toggle('portal-reset-password-body');
    }

    componentWillUnmount() {
        document.body.classList.toggle('portal-reset-password-body');
    }

    hideAlert() {
        this.setState({
            error: false,
            error_message: '',
            success_message: '',
        }); 
    }

    render() {

        if(this.props.logged_in === true) {
            return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
        }

        let LoginPageLink = <p>{this.state.success_message}. Click <Link to={`${process.env.PUBLIC_URL}/login`}>here</Link> to go to login page</p>;

        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                {this.state.error === true ? <div className="my-4 mx-auto"> <Alert hideAlert={this.hideAlert} message={`${this.state.error_message}.`} type="danger" /> </div> : "" }
                {this.state.success_message ? <div className="my-4 mx-auto"> <Alert hideAlert={this.hideAlert} message={LoginPageLink} type="success" /> </div> : "" }
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={this.state.uploaded_logo ? this.state.uploaded_logo : Logo}
                        alt="Logo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forget password</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6 portal-reset-password" action="#" method="POST">
                            <div>
                                <label htmlFor="user_name" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="user_name"
                                        name="user_name"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        autoFocus
                                        value={this.state.user_name} 
                                        onChange={this.handleChange}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <button type='submit' disabled={this.state.loading === true ? 'disabled' : ''} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary" onClick={this.handleSubmit}> 
                                    {this.state.loading === true ? "Loading..." : "Reset Password" }
                                </button>
                            </div>
                            <div className="flex items-center justify-between flex-row-reverse">
                                <div className="text-sm">
                                    <Link to={`${process.env.PUBLIC_URL}/login`} className="font-medium text-skin-primary-dark hover:text-skin-primary-darker">
                                        Back to login
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgetPassword;
