import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Logo from '../../../images/logo.png';

class Navigation extends Component
{
    state = {
        uploaded_logo: 'https://crm.bit4finance.de/cache/logo.png',
    }

    render() {
        return(
            <nav className="navbar navbar-dark navbar-expand-lg fixed-top bg-primary flex-md-nowrap p-0 shadow">
                <Link to={`${process.env.PUBLIC_URL}/`} className="navbar-brand col-sm-3 col-md-2 mr-0 text-center">
                    <img height="20px;" alt="Logo" src={this.state.uploaded_logo ? this.state.uploaded_logo : Logo}></img>
                </Link>
                <ul className="navbar-nav px-3 ml-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {this.props.customer_name}
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link to={`${process.env.PUBLIC_URL}/my-profile`} className="dropdown-item"> <span data-feather="user"></span> My Profile</Link>
                            <a href='#' onClick={() => this.props.updateLoginStatus(false)} className="dropdown-item"> <span data-feather="log-out"></span> Sign out </a>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Navigation;
